input.accordionCheck {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.tabs .card {
    margin: 0 !important;
}

.tabs {
    border-radius: 8px;
    overflow-y: auto;
    box-shadow: 0 4px 4px -2px #F7F5FA55;
}
  
.tab {
    width: 100%;
    overflow-y: auto;
}

.tab-label {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    background: #F7F5FA;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 0px;
    /* Icon */
}

.tab-label:hover {
    background: #F7F5FA;
}

.tab-label::after {
    content: "❯";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.35s;
 }

.tab-content {
    max-height: 0;
    transition: all 0.35s;
    box-shadow: 0px 3px 0px 0px #F7F5FA;
    overflow-y: auto;
}

.tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #2c3e50;
    cursor: pointer;
}

.tab-close:hover {
    background: #F7F5FA;
}
  
.accordionCheck:checked + .tab-label {
    background: #F7F5FA;
}

.accordionCheck:checked + .tab-label::after {
    transform: rotate(90deg);
}
  
.accordionCheck:checked ~ .tab-content {
    max-height: 100vh;
}