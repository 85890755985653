@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');

/* [dir="rtl"]  */.MuiInputLabel-root {
  line-height: 1.3em !important;
}

[dir="rtl"] *:not(span, i) {
  font-family: 'Cairo', sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

[dir="rtl"] .MuiTypography-root {
  font-family: 'Cairo', sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

:root {
  --main: #333
}

.MuiOutlinedInput-root {
  min-height: 45px !important;
}

button:disabled {
  opacity: .5;
}

  .css-1vz7d22-MuiButtonBase-root-MuiMenuItem-root.Mui-selected
  { 
     background-color: transparent; 
}

.table-order {
  border-bottom: 0 !important;
}

.order-details-table .table-details .table tfoot tr.table-order:nth-child(3) td {
  padding: 10px 15px !important
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.MuiPaper-root {
  background-color: #fff !important;
}

*[aria-selected="false"] {
  background-color: transparent;
}

.gsECjm {
  position: fixed;
}


.MuiMenuItem-root {
  margin-bottom: 3px !important;
}

.spi {
  transform: rotate(180deg);
}

.spinner {
  animation-name: refreshing;
  animation-duration: .6s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

@keyframes refreshing {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg)}
}

 /* side nav */
.sidenav-collapse-label::after {
content: "";
display: none
}

.sidenav-arrow { 
  transition: all .3s; 
  position: absolute;
  right: 25px;
  top: 15px;
  color: white;
  z-index: -1;
}

[dir=rtl] .sidenav-arrow { 
  transition: all .3s; 
  position: absolute;
  right: unset;
  left: 25px;
  top: 15px;
  color: white;
  transform: rotate(180deg)
}

.accordionCheck:checked + .tab-label .sidenav-arrow {
  transform: rotate(90deg);
}

[dir=rtl] .accordionCheck:checked + .tab-label .sidenav-arrow {
  transform: rotate(90deg);
}

 /* Scroll Bar */
 .MuiDrawer-paper::-webkit-scrollbar {
  display: none;
 }

::-webkit-scrollbar {
  width: 5px;
  max-height: 10px;
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
::-webkit-scrollbar-thumb {
  background-color: #333 !important;
  border-radius: 3px;
}

 /* HEADER */

 .logo {
  display: flex;
  margin: 0px auto 20px auto;
  height: 150px;
  max-width: 100%;
}

/* Editor Box */

.rdw-editor-main {
  min-height: 200px
}

/* Text Field Legend */
.css-nnbavb {
  display: none;
}

/* Toastify CSS Styles */

.Toastify__toast-body {
    font-size: 16px;
}

.skeleton-loader-wrapper > span{
  width: 100%;
}

/* rtl */
[dir=rtl] .order-details-table .table-details .table tfoot tr td {
  text-align: right;
}


[dir=rtl] .title-header-block .card-order-section ul li:first-child {
margin-right: 15px
}

[dir=rtl] .title-header-block .card-order-section ul li:last-child {
margin-left: 0;
margin-right: 15px
}

[dir=rtl] .title-header-block .card-order-section ul li::before {
  left: -15px;
  right: unset
}